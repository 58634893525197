<template>
    <div>
        <div class="page-header back-image5"></div>
        <div class="col-md-1">
            <div class="wrapper-content bg-white pinside40">
                <div class="cont-table">
                    <a-row :gutter="24" type="flex">
                        <a-col :span="24" class="mb-24">
                            <div class="div-table-container">
                                <div class="card-header-table">
                                    <div class="bg-gradient-primary">
                                        <h5 class="title-table">Listado de Invitaciones Públicas</h5>
                                    </div>
                                </div>

                                <table class="table">
                                    <thead class="tr-thead">
                                        <tr class="tr-columns-data">
                                            <th class="th-colum-item">Codigo</th>
                                            <th class="th-colum-item">Tipo de Proceso</th>
                                            <th class="th-colum-item">Estado</th>
                                            <th class="th-colum-item">Entidad</th>
                                            <th class="th-colum-item">Objeto</th>
                                            <th class="th-colum-item">Cuantía</th>
                                            <th class="th-colum-item">Fecha</th>
                                        </tr>
                                    </thead>
                                    <tbody class="tbody-container">
                                        <tr class="tr-body" v-for="(item, index) in listTenders" :key="index">
                                            <div class="tr-columns-data-body">
                                                <td class="th-colum-item">
                                                    <router-link :to="`/descriptionTenders/${item._id}`" class="link-acces">
                                                        {{ item.number_proccess }}
                                                    </router-link>
                                                </td>
                                                <td class="th-colum-item"> {{ item.type_proccess }} </td>
                                                <td class="th-colum-item"> {{ item.status }} </td>
                                                <td class="th-colum-item"> {{ item.entity }} </td>
                                                <td class="th-colum-item"> {{ item.typeObject }} </td>
                                                <td class="th-colum-item"> {{ item.cuantia }} </td>
                                                <td class="th-colum-item"> {{ item.date }} </td>

                                            </div>
                                            <br>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../../../../firebaseConfig';

export default {
    data() {
        return {
            listTenders: []
        }
    },
    mounted() {
        this.getAllTenders()
    },

    methods: {
        async getAllTenders() {
            this.isLoading = true
            await db.collection('tenders')
                .get()
                .then((querySnapshot) => {
                    this.dataGetDocs = querySnapshot.docs;
                    const tenders = [];
                    querySnapshot.forEach((doc) => {
                        tenders.push(doc.data());
                    });
                    this.listTenders = tenders;
                    this.isLoading = false
                    this.listTenders = tenders;
                })
        },

    }
}


</script>
<style lang="css">
.back-image5 {
    background: url('../../../assets/img/fotoContratacion.png') no-repeat center;
    background-size: cover;
}

.cont-table {
    max-width: 1100px;
    margin: 0 auto;
}

.btn-new-tender {
    padding: 10px;
    border: 0;
    color: white;
    background-color: transparent;
}

.link-acces {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(14, 13, 13);
}

.link-acces:hover {
    color: var(--colorBluePrimary)
}
</style>
